import React, { useState } from 'react';
import {
	IconButton,
	TextField,
	Typography,
	Zoom,
	Autocomplete,
} from '@mui/material';
import { EditRounded, CheckRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { OfferToolTip } from '../../../../helpers';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import BackdropLoading from '../../../../components/BackdropLoading';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { editOfferClassification } from '../../../../services/notifications';

interface Props {
	rowData: any;
	handleReload: () => void;
}

const ClassificationTypeSelect: React.FC<Props> = ({
	rowData,
	handleReload,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, classificationTypes, userData, selectedClient } =
		usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const [readOnly, setReadOnly] = useState(false);
	const [hover, setHover] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reasonDenouncement, setReasonDenouncement] = useState<string>(
		rowData.classificationType.classificationTypeID || ''
	);

	const classificationTypeIds = classificationTypes.map((type) => type.id);

	const useStyles = makeStyles(() => ({
		option: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme?.textColorHigh : '',
		},
		paper: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
		},
	}));

	const classes = useStyles();

	/** Filter excessive roles if user has more than one (temporary) */
	const handleSaveData = async () => {
		setLoading(true);
		const payload = {
			offerClassificationID: [
				rowData.offerClassification.offerClassificationID,
			],

			classificationTypeID: reasonDenouncement,
			denounceStatusID: 'CLASSIFICADA',
			clientID: selectedClient.id,
			observation: '',
		};
		const response: any = await editOfferClassification(payload);
		if (response.status < 300) {
			enqueueSnackbar(t(response.data[0].detail), {
				variant: 'success',
			});
			handleReload();
		} else {
			enqueueSnackbar(
				t(
					response?.data?.detail ||
						t('Erro ao editar a denúncia. Tente novamente mais tarde.')
				),
				{
					variant: 'error',
				}
			);
			setReasonDenouncement(rowData.classificationType.classificationTypeID);
		}
		setLoading(false);
	};

	const handleKeyPress = (event) => {
		if (event.keyCode === 13) {
			handleSaveData();
		}
		if (event.keyCode === 27) {
			setReadOnly(false);
		}
	};

	/** Checks if user meets conditions to edit the item */
	const selectDisable = () => {
		if (
			userData.roles?.indexOf('Desenvolvedor') !== -1 ||
			userData.roles?.indexOf('Administrador da ferramenta') !== -1 ||
			userData.roles?.indexOf('Administrador do cliente') !== -1 ||
			userData.roles?.indexOf('Analista do cliente') !== -1
		) {
			return false;
		}
		if (rowData?.classificationTypeID === 'SUSPT') {
			return false;
		}
		if (rowData?.denouncerEmail === userData.email) {
			return false;
		}
		return true;
	};

	// TODO Role change submit button (CheckIcon) to auto-submit on role select

	return (
		<div>
			{loading && <BackdropLoading smallBackdrop />}
			{readOnly ? (
				<Autocomplete
					id="role-select"
					style={{ width: '100%' }}
					options={classificationTypeIds}
					onChange={(_, newValue) => {
						setReasonDenouncement(newValue || '');
					}}
					classes={{
						option: classes.option,
						paper: classes.paper,
					}}
					value={reasonDenouncement}
					isOptionEqualToValue={(option, value) => option === value}
					autoHighlight
					autoComplete
					autoSelect
					selectOnFocus
					disableClearable
					getOptionLabel={(option) => t(`ClassificationType.${option}`)}
					renderInput={(params) => (
						<Line
							style={{
								justifyContent: 'space-around',
								paddingLeft: 10,
							}}
						>
							<TextField
								variant="standard"
								{...params}
								onKeyDown={handleKeyPress}
								size="small"
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password',
									style: {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorMedium
												: '',
									},
								}}
								sx={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',

									'& .MuiInput-underline': {
										':after': {
											borderBottomColor:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
										':before': {
											borderBottomColor:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
									},
									'& .MuiInputBase-root': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorMedium
												: '',
									},
								}}
							/>
							<IconButton
								data-testid="confirmedChangeRole"
								onClick={() => {
									handleSaveData();
									setReadOnly(false);
								}}
							>
								<CheckRounded
									style={{
										fontSize: 16,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
								/>
							</IconButton>
						</Line>
					)}
				/>
			) : (
				<div>
					<OfferToolTip
						title={t(`ClassificationType.${reasonDenouncement}`)}
						aria-label="inativo"
						enterDelay={700}
						enterNextDelay={700}
						arrow
						TransitionComponent={Zoom}
					>
						<Line
							onMouseOver={() => setHover(true)}
							onMouseOut={() => setHover(false)}
							onFocus={() => setHover(true)}
							onBlur={() => setHover(false)}
							style={{ justifyContent: 'center' }}
						>
							<Typography
								style={{
									paddingLeft: 25,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorMedium
											: '',
								}}
								noWrap
								align="left"
							>
								{t(`ClassificationType.${reasonDenouncement}`)}
							</Typography>
							<IconButton
								data-testid="testIcon"
								disabled={selectDisable()}
								onClick={() => {
									setReadOnly(!readOnly);
								}}
								style={hover ? { opacity: 1 } : { opacity: 0 }}
							>
								<EditRounded
									data-testid="openAutocomplete"
									style={{
										fontSize: 16,
										marginTop: -2,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
								/>
							</IconButton>
						</Line>
					</OfferToolTip>
				</div>
			)}
		</div>
	);
};
export default ClassificationTypeSelect;
