import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Typography,
	Zoom,
} from '@mui/material';
import { CloseRounded, Edit } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { api, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import { Footer, Line } from '../../../../styles';
import SnackbarMessage from '../../../../components/SnackbarMessage';
import '../../../../i18n';
import { OfferToolTip } from '../../../../helpers';
import { usePO } from '../../../../utils/POContext';
import RightColumn from '../../BuildSearchForm/RightColumn';
import LeftColumn from '../../BuildSearchForm/LeftColumn';
import LoadingDots from '../../../../components/LoadingDots';
import { enqueueSnackbar } from 'notistack';

interface Props {
	rowData: any;
	reloadTable: () => void;
}

const EditSearchModal: React.FC<Props> = ({ rowData, reloadTable }: Props) => {
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const { selectedTheme, cursorMode, selectedClient } = usePO();
	const { t } = useTranslation();
	const editSearchFormRef = useRef<FormHandles>(null);

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const searchTypeOptions = [
		{ name: t('Buscas.Tabs.Nova Busca.Buscador'), value: 'BUSCADOR' },
		{ name: 'IDE', value: 'IDE' },
	];

	const searchTypeData = {
		name: rowData?.searchTypeName,
		value: rowData?.searchTypeID,
	};

	// Build the search tag: searchTitle|SEARCH|userID
	const [searchTitle, setSearchTitle] = useState<string>(rowData?.title || '');
	const [termsToSearch, setTearmsToSearch] = useState<any[]>([rowData?.terms]);
	const [excludingTermsToSearch, setExcludingTearmsToSearch] = useState<any[]>(
		rowData?.excludingTerms || []
	);
	const [minPrice, setMinPrice] = useState('0,00');
	const [maxPrice, setMaxPrice] = useState('0,00');
	const [category, setCategory] = useState<string>('');
	const [getSolds, setGetSolds] = useState(false);
	const [step, setStep] = useState<string>('0,00');
	const [selectedPlatforms, setSelectedPlatforms] = useState<any[]>([
		rowData?.platform,
	]);

	const [periodicity, setPeriodicity] = useState<string>(
		rowData?.periodID.toString()
	);
	const [isUniqueSearch, setIsUniqueSearch] = useState(false);
	const [searchType, setSearchType] = useState<any>(searchTypeData);

	const [description, setDescription] = useState<string>(
		rowData?.description || ''
	);

	const [renderKey, setRenderKey] = useState(0);
	const [openExistentSearchDialog, setOpenExistentSearchDialog] =
		useState(false);
	const [formErrors, setFormErrors] = useState<any>({});

	const extractSearchParams = (terms: string) => {
		const params = new URLSearchParams(terms.replace(/\?/g, '&'));
		return {
			minPrice: params.get('min_price') || '0,00',
			maxPrice: params.get('max_price') || '0,00',
			category: params.get('category') || '',
			getSolds: params.get('get_solds') === 'true',
			step: params.get('step') || '0,00',
		};
	};

	useEffect(() => {
		if (rowData?.terms) {
			const { minPrice, maxPrice, category, getSolds, step } =
				extractSearchParams(rowData.terms);

			setMinPrice(minPrice);
			setMaxPrice(maxPrice);
			setCategory(category);
			setGetSolds(getSolds);
			setStep(step);
		}
	}, [rowData]);

	useEffect(() => {
		if (rowData?.periodID === -1) {
			setIsUniqueSearch(true);
		} else {
			setIsUniqueSearch(false);
		}
	}, [rowData]);

	const handleClose = () => {
		setOpen(false);
		if (!rowData) {
			handleResetForm();
		}
	};

	const handleResetForm = () => {
		setSearchTitle('');
		setSelectedPlatforms([]);
		setPeriodicity('7');
		setSearchType('BUSCADOR');
		setTearmsToSearch([]);
		setExcludingTearmsToSearch([]);
		setDescription('');
		setFormErrors({});
		setMinPrice('');
		setMaxPrice('');
		setCategory('');
		setStep('');
		setGetSolds(false);
		setIsUniqueSearch(false);
		setRenderKey((prevKey) => prevKey + 1);
	};

	const renderAction2 = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Buscas Salvas.Editar Busca')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setOpen(true)}
				style={{ padding: '10px 5px', marginRight: -2 }}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Edit
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.disableBackground,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const handleSendData = async () => {
		try {
			setLoading(true);
			const periodValue = isUniqueSearch ? -1 : Number(periodicity);
			const searchPayload = {
				title: searchTitle,
				description: description,
				platformINcountryID: [rowData.platformINcountryID],
				tagID: rowData.tagID,
				terms: termsToSearch[0],
				excludingTerms: excludingTermsToSearch,
				period: periodValue,
			};
			const searchResponse = await api.put(
				`/searches/${rowData.searchID}/clients/${selectedClient.id}`,
				searchPayload
			);
			setLoading(false);
			enqueueSnackbar(searchResponse.data.detail, { variant: 'success' });
			handleClose();
			reloadTable();
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(t('Erro interno. Tente novamente mais tarde'), {
				variant: 'error',
			});
		}
	};

	async function handleSubmit() {
		const formData = {
			title: searchTitle,
			periodicity: periodicity,
		};
		try {
			const schema = Yup.object().shape({
				title: Yup.string()
					.min(
						2,
						t(
							'Buscas.Tabs.Nova Busca.O nome da busca precisa ter no mínimo 2 caracteres'
						)
					)
					.required(t('Buscas.Tabs.Nova Busca.O nome da busca é obrigatório')),
				periodicity: Yup.string().notOneOf(
					['0', '1', ''],
					t(
						'Buscas.Tabs.Nova Busca.O campo periodicidade deve ser maior que 1 ou selecione busca única'
					)
				),
			});
			await schema.validate(formData, {
				abortEarly: false,
			});
			setFormErrors({});
			handleSendData();
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	}

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'main':
				return '';
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const renderFooter = () => (
		<Footer
			style={{
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#f2f2f2',
				padding: 20,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
			}}
		>
			<Line
				style={{
					justifyContent: 'flex-end',
				}}
			>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						size="small"
						variant="contained"
						onClick={handleSubmit}
						style={{
							color:
								selectedTheme.tone === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{t('Buscas.Tabs.Buscas Salvas.Salvar')}
					</Button>
				)}
			</Line>
		</Footer>
	);

	return (
		<>
			{renderAction2()}
			<Dialog
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="lg"
				fullWidth
				disableEscapeKeyDown
				style={{ cursor: cursorMode }}
			>
				<DialogTitle
					style={{
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						color: '#FFFFFF',
						display: 'flex',
					}}
					id="max-width-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
						}}
					>
						<Typography
							noWrap
							style={{
								color:
									selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
								fontSize: 20,
								fontWeight: 'bold',
								maxWidth: '90%',
							}}
						>
							{t('Buscas.Tabs.Buscas Salvas.Editar Busca')}
						</Typography>
						<IconButton
							onClick={handleClose}
							style={{ marginRight: '-16px' }}
							disabled={loading}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'main'
											? selectedTheme.primary
											: 'white',
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
				<Divider
					sx={{
						background: selectedTheme.id === 'dark' && selectedTheme.footerLine,
					}}
				/>
				<Divider
					sx={{
						background: selectedTheme.id === 'dark' && selectedTheme.footerLine,
					}}
				/>
				<DialogContent
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
					}}
				>
					<Form
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
						placeholder=""
						onSubmit={handleSubmit}
						ref={editSearchFormRef}
						initialData={rowData}
					>
						<Line
							style={{
								height: '100%',
								alignItems: 'start',
								padding: '20px 30px',
								marginBottom: 10,
							}}
						>
							<LeftColumn
								searchTitle={searchTitle}
								setSearchTitle={setSearchTitle}
								selectedPlatforms={
									rowData?.platform ? [rowData.platform] : selectedPlatforms
								}
								setSelectedPlatforms={setSelectedPlatforms}
								termsToSearch={termsToSearch}
								setTearmsToSearch={setTearmsToSearch}
								excludingTermsToSearch={excludingTermsToSearch}
								setExcludingTermsToSearch={setExcludingTearmsToSearch}
								description={description}
								setDescription={setDescription}
								setGetSolds={setGetSolds}
								getSolds={getSolds}
								category={category}
								setCategory={setCategory}
								minPrice={minPrice}
								maxPrice={maxPrice}
								setMinPrice={setMinPrice}
								setMaxPrice={setMaxPrice}
								step={step}
								setStep={setStep}
								isEditing={true}
								formErrors={formErrors}
							/>
							<RightColumn
								periodicity={periodicity}
								setPeriodicity={setPeriodicity}
								searchType={searchType}
								setSearchType={setSearchType}
								searchTypeOptions={searchTypeOptions}
								selectedPlatforms={selectedPlatforms}
								setSelectedPlatforms={setSelectedPlatforms}
								isEditing={true}
								isUniqueSearch={isUniqueSearch}
								setIsUniqueSearch={setIsUniqueSearch}
								setDescription={setDescription}
								description={description}
								formErrors={formErrors}
								renderKey={renderKey}
							/>
						</Line>
					</Form>
				</DialogContent>
				{renderFooter()}
			</Dialog>
			<SnackbarMessage
				message={t(
					`Buscas.Tabs.Buscas Salvas.já possui uma busca cadastrada com estes termos. Acesse a aba 'Buscas Salvas' para repetir a busca, realizar modificações nos parâmetros ou excluir os resultados anteriores.`
				)}
				type="warning"
				open={openExistentSearchDialog}
				setOpen={setOpenExistentSearchDialog}
			/>
		</>
	);
};

export default EditSearchModal;
