import React, { useState } from 'react';
import {
	Autocomplete,
	Chip,
	Divider,
	IconButton,
	InputAdornment,
	Slide,
	TextField,
	Typography,
	Zoom,
} from '@mui/material';
import {
	ChevronRightRounded,
	CloseRounded,
	KeyboardBackspace,
	Save,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changePanelState } from '../../redux/slices/panelControl';
import { Column, Line } from '../../styles';
import { usePO } from '../../utils/POContext';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { DateUtils } from 'react-day-picker';
import FormDivider from '../FormDivider';
import {
	DataSearch,
	DateRange,
	MultiDropdownList,
	SelectedFilters,
} from '@appbaseio/reactivesearch';
import TagSelector from '../TagSelector';
import { OfferToolTip } from '../../helpers';

import { useViewSearch } from '../../hooks/ViewSearch';
import ViewDenouncementItems from './ViewDenouncementsItems';
import DenounceUserFilter from './DenounceUserFilter';
import {
	updateAuthorsFilters,
	updateClassificationsFilters,
	updateNotificationsFilters,
	removeSpecificFilter,
} from '../../redux/slices/filterSlice';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import RangeSelector from '../RangeSelector';
import RangeSelectorPrice from '../RangeSelectorPrice';
import { NumericFormat } from 'react-number-format';

//List of all the data types for each input, based on ReactiveSearch queries.
type authorSearchData = 'author.nickname' | 'author.authorName';
type permalinkSearchData = 'offer.permalink';
type offerIDSearchData = 'offer.offerID';
type categorySearchData = 'offerCategory.keyword' | 'offerCategory';
type anatelSearchData = 'offerData.anatel_number.value';
type citySearchData =
	| 'cityName'
	| 'author.cityName.keyword'
	| 'author.cityName';
type classificationTypeData =
	| 'classificationType.classificationTypeID'
	| 'offerClassification.classificationTypeID';
type countrySearchData =
	| 'countryName'
	| 'author.countryName.keyword'
	| 'author.countryCode';
type dateRangeSearchData = 'created';
type dateRangeSeekData = 'updated';
type denouncementIDSearchData = 'denouncementID';
type denouncerSearchData = 'denouncer.denouncerName';
type nameSearchData = 'authorName';
type observationSearchData =
	| 'offerClassification.observation'
	| 'denouncementObservation';
type idAuthorSearchData = 'authorID';
type platformSearchData =
	| 'platformINcountryID'
	| 'platformINcountryID.keyword'
	| 'offer.platformINcountryID.keyword'
	| 'offer.platformINcountryID';
type sourceSearchData = 'sourceID.keyword' | 'offer.sourceID';
type stateSearchData =
	| 'stateName'
	| 'author.stateName.keyword'
	| 'author.stateName';

type tagSearchData = 'tagID';
type tagSelectorSearchData = 'tags.tagID.keyword' | 'offer.tags.tagID.keyword';
type titleSearchData = 'title' | 'offer.title';

interface Props {
	authorNameField?: authorSearchData[];
	permalinkSearchField?: permalinkSearchData;
	offerIDSearchField?: offerIDSearchData;
	categorySearchField?: categorySearchData;
	anatelSearchField?: anatelSearchData;
	citySearchField: citySearchData;
	classificationTypeField?: classificationTypeData;
	countrySearchField: countrySearchData;
	dateRangeSeekField?: dateRangeSeekData;
	dateRangeField?: dateRangeSearchData;
	denounceFilterField?: boolean;
	denouncementIDField?: denouncementIDSearchData;
	denouncementReasonField?: boolean;
	denouncerSearchField?: denouncerSearchData;
	nameSearchField?: nameSearchData;
	idAuthorSearchField?: idAuthorSearchData;
	observationSearchField?: observationSearchData;
	platformSearchField: platformSearchData;
	priceSliderField?: boolean;
	sourceSearchField?: sourceSearchData;
	stateSearchField: stateSearchData;
	tagSearchField?: tagSearchData;
	tagSelectorField?: tagSelectorSearchData;
	titleSearchField?: titleSearchData;
	checkboxDenouncementsItemsField?: boolean;
	checkboxTagIDItemsField?: boolean;
	checkboxAllowedItemsField?: boolean;
	followersSearchField?: string;
	followers?: boolean;
	itemsSoldSearchField?: string;
	itemsSold?: boolean;
}

const FilterBarComponent: React.FC<Props> = ({
	authorNameField,
	permalinkSearchField,
	offerIDSearchField,
	categorySearchField,
	anatelSearchField,
	citySearchField,
	classificationTypeField,
	countrySearchField,
	dateRangeField,
	dateRangeSeekField,
	denounceFilterField,
	denouncementIDField,
	denouncementReasonField,
	denouncerSearchField,
	nameSearchField,
	idAuthorSearchField,
	observationSearchField,
	platformSearchField,
	priceSliderField,
	sourceSearchField,
	stateSearchField,
	tagSearchField,
	tagSelectorField,
	titleSearchField,
	checkboxDenouncementsItemsField,
	checkboxTagIDItemsField,
	checkboxAllowedItemsField,
	followers,
	itemsSold,
	followersSearchField,
	itemsSoldSearchField,
}) => {
	const { t } = useTranslation();
	const {
		selectedTheme,
		tagsActiveClient,
		selectedClient,
		classificationTypes,
	} = usePO();

	const {
		saveTagID,
		setSaveTagID,
		saveINcountryID,
		saveDenouncementID,
		setSaveDenouncementID,
	} = useViewSearch();

	const currentURL = window.location.href;

	const filterType = currentURL.includes('classificacoes')
		? 'classifications'
		: currentURL.includes('notificacoes')
			? 'notifications'
			: 'authors';

	//Unique variables for the use of Redux.
	const dispatch = useDispatch();
	const vertMenuState = useSelector((state: any) => state.vertMenu);
	const panelControlList = useSelector((state: any) => state.panelControl);
	const filtersRedux = useSelector((state: any) => state.filters);

	const loading = false;
	const { enqueueSnackbar } = useSnackbar();

	// Name of the redux filter to be saved
	const [nameFilter, setNameFilter] = useState('');

	const useStyles = makeStyles(() => ({
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();
	/*
	Filters save by redux it has empty default filters
	it is possible to save several different filters for each page.
	*/
	const [filterRedux, setFilterRedux] = useState(
		filtersRedux[filterType]['defaultFilters']
	);

	const [mouseOver, setMouseOver] = useState(false);

	//I've added the Vertical tabs button to the toolbar. You can change this anytime here or in Settings.
	const drawerWidth = window.innerHeight < 901 ? 60 : 70;
	const drawerWidthOpen = window.innerHeight < 901 ? 200 : 230;

	//Properties for the reported items field of this client
	const [viewDenouncements, setViewDenouncements] = useState(
		filterRedux.viewDenouncements
	);

	// Checkbox 1
	const [viewDenouncementItems, setViewDenouncementItems] = useState(
		filterRedux.viewDenouncementItems
	);

	// Checkbox 2
	const [viewAllowedItems, setViewAllowedItems] = useState(
		filterRedux.viewAllowedItems
	);

	// Checkbox 3
	const [viewTagIDItems, setViewTagIDItems] = useState(
		filterRedux.viewTagIDItems
	);

	//Properties for the tagSelectorField field
	const [keyLogic, setKeyLogic] = useState(0);
	const [orLogic, setOrLogic] = useState(filterRedux.orLogic);

	//Properties for the classificationTypeField field
	const [classficationTypesID, setClassficationTypesID] = useState<any[]>(
		filterRedux.classficationTypesID
	);
	const [classficationType, setClassficationType] = useState<any[]>(
		filterRedux.classficationTypesID
	);

	//Properties for the NameSearchField field
	const [nameSearch, setNameSearch] = useState(filterRedux.nameSearch);
	const [nameSearchValue, setNameSearchValue] = useState(
		filterRedux.nameSearch
	);

	//Properties for the titleSearchField field
	const [titleSearch, setTitleSearch] = useState(filterRedux.titleSearch);
	const [titleSearchValue, setTitleSearchValue] = useState(
		filterRedux.titleSearch
	);

	//Properties for the observationSearchField field
	const [observationSearch, setObservationSearch] = useState(
		filterRedux.observationSearch
	);
	const [observationSearchValue, setObservationSearchValue] = useState(
		filterRedux.observationSearch
	);

	//Properties for the anatelSearchField field
	const [anatelSearch, setAnatelSearch] = useState(filterRedux.anatelSearch);
	const [anatelSearchValue, setAnatelSearchValue] = useState(
		filterRedux.anatelSearch
	);

	const [filterResetAutocomplete, setfilterResetAutocomplete] = useState('');

	//Properties for the authorNameField field
	const [sellerSearch, setSellerSearch] = useState(filterRedux.sellerSearch);
	const [sellerSearchValue, setSellerSearchValue] = useState(
		filterRedux.sellerSearch
	);

	const [permalinkSearch, setPermalinkSearch] = useState(
		filterRedux.permalinkSearch
	);
	const [permalinkSearchValue, setPermalinkSearchValue] = useState(
		filterRedux.permalinkSearch
	);

	const [offerIDSearch, setOfferIDSearch] = useState(
		filterRedux.permalinkSearch
	);
	const [offerIDSearchValue, setOfferIDSearchValue] = useState(
		filterRedux.permalinkSearch
	);

	//Properties for the DenouncementIDField field
	const [denouncementSearch, setDenouncementSearch] =
		useState(saveDenouncementID);
	const [denouncementSearchValue, setDenouncementSearchValue] =
		useState(saveDenouncementID);
	const [reloadDenouncementSearch, setReloadDenouncementSearch] = useState(0);

	//Properties for the TagSearch field
	const [tagSearch, setTagSearch] = useState(saveTagID);
	const [tagSearchValue, setTagSearchValue] = useState(saveTagID);

	const [reloadTagSearch, setReloadTagSearch] = useState(0);

	const [existentTag, setExistentTag] = useState<any[]>(filterRedux.saveTags);

	const [tagsID, setTagsID] = useState<any[]>(filterRedux.tagsID);

	//denounceFilterField
	const [selectedUser, setSelectedUser] = useState<string>(
		filterRedux.selectedUser || ''
	);

	const [denouncements, setDenouncements] = useState('');

	//SourceSearch MultiDropdownList field
	const [sourceSearch, setSourceSearch] = useState(filterRedux.sourceSearch);

	//MultiDropdownLis CategorySearch field
	const [categorySearch, setCategorySearch] = useState(
		filterRedux.categorySearch
	);

	//Properties for the priceSliderField field
	const [priceSelect, setPriceSelect] = useState({
		start: 0,
		end: 999999999,
	});

	// Range Selector followers and soldItems fields
	const [followersRange, setFollowersRange] = useState({
		start: 0,
		end: 999999999,
	});
	const [itemsSoldRange, setItemsSoldRange] = useState({
		start: 0,
		end: 999999999,
	});

	//platformSearch MultiDropdownLis field
	const [platformSearch, setPlatformSearch] = useState(
		filterRedux.platformSearch
	);

	//DenouncerSearch MultiDropdownLis field
	const [denouncerSearch, setDenouncerSearch] = useState(
		filterRedux.denouncerSearch
	);

	//idSearch MultiDropdownLis field
	const [idAuthorSearch, setAuthorIdSearch] = useState(
		filterRedux.idAuthorSearch
	);

	//countrySearch MultiDropdownLis field
	const [countrySearch, setCountrySearch] = useState(filterRedux.countrySearch);

	//citySearch MultiDropdownLis field
	const [citySearch, setCitySearch] = useState(filterRedux.citySearch);

	//stateSearch MultiDropdownLis field
	const [stateSearch, setStateSearch] = useState(filterRedux.stateSearch);

	//dateRangeSearch MultiDropdownLis field
	const [dateRangeSearch, setDateRangeSearch] = useState(
		filterRedux.dateRangeSearch
	);

	//dateRangeSeek MultiDropdownLis field
	const [dateRangeSeek, setDateRangeSeek] = useState(filterRedux.dateRangeSeek);

	//Function that modifies the logic used in tags.
	const handleChangeLogic = () => {
		setOrLogic(!orLogic);
		setKeyLogic(keyLogic + 1);
	};

	//Function that modifies the date used in the dateRangeField component.
	function parseDate(str, format, locale) {
		const parsed = dateFnsParse(str, format, locale);
		if (DateUtils.isDate(parsed)) {
			return parsed;
		}
		return undefined;
	}

	function formatDate(date, format, locale) {
		return dateFnsFormat(date, format, { locale });
	}

	const dateFormat = 'dd/MM/yyyy';

	//
	const handleCloseOnBlur = () => {
		if (!mouseOver) {
			dispatch(changePanelState(''));
		}
	};

	function setFilterState(filterRedux) {
		setNameSearch(filterRedux.nameSearch);
		setNameSearchValue(filterRedux.nameSearch);
		setTitleSearchValue(filterRedux.titleSearch);
		setTitleSearch(filterRedux.titleSearch);
		setObservationSearchValue(filterRedux.observationSearch);
		setObservationSearch(filterRedux.observationSearch);
		setAnatelSearch(filterRedux.anatelSearch);
		setAnatelSearchValue(filterRedux.anatelSearch);
		setSellerSearch(filterRedux.sellerSearch);
		setSellerSearchValue(filterRedux.sellerSearch);
		setPermalinkSearch(filterRedux.permalinkSearch);
		setPermalinkSearchValue(filterRedux.permalinkSearch);
		setOfferIDSearchValue(filterRedux.offerIDSearch);
		setExistentTag(filterRedux.saveTags);
		setTagsID(filterRedux.tagsID);
		setSourceSearch(filterRedux.sourceSearch);
		setCategorySearch(filterRedux.categorySearch);
		setPlatformSearch(filterRedux.platformSearch);
		setDenouncerSearch(filterRedux.denouncerSearch);
		setClassficationTypesID(filterRedux.classficationTypesID);
		setClassficationType(filterRedux.classficationType);
		setOrLogic(filterRedux.orLogic);
		setPriceSelect(filterRedux.priceSelect);
		setAuthorIdSearch(filterRedux.idAuthorSearch);
		setCountrySearch(filterRedux.countrySearch);
		setCitySearch(filterRedux.citySearch);
		setStateSearch(filterRedux.stateSearch);
		setDateRangeSearch(filterRedux.dateRangeSearch);
		setDateRangeSeek(filterRedux.dataRangeSeek);
		setSelectedUser(filterRedux.selectedUser);
		setDenouncements(filterRedux.denouncements);
		setFollowersRange(filterRedux.followersRange);
		setItemsSoldRange(filterRedux.itemsSoldRange);
		// Filtro das checkboxes
		setViewDenouncements(filterRedux.viewDenouncements);
		setViewDenouncementItems(filterRedux.viewDenouncementItems);
		setViewAllowedItems(filterRedux.viewAllowedItems);
		setViewTagIDItems(filterRedux.viewTagIDItems);
	}

	/*
	saveDefaultFilters - This function is responsible for saving filter into redux on the current URL.
	
	It takes a nameFilter as a parameter and dispatches the appropriate filter update action
	with filterName and filterData in redux type (classificacoes, notificacoes, or autores).
	
	- nameFilter: The name of the filter to save.
	*/
	const saveDefaultFilters = (nameFilter) => {
		if (currentURL.includes('classificacoes')) {
			dispatch(
				updateClassificationsFilters({
					filterName: nameFilter,
					filterData: {
						titleSearch: titleSearch,
						sellerSearch: sellerSearch,
						anatelSearch: anatelSearch,
						saveTags: existentTag,
						tagsID: tagsID,
						orLogic: orLogic,
						priceSelect: priceSelect,
						selectedUser: selectedUser,
						denouncements: denouncements,
						sourceSearch: sourceSearch,
						categorySearch: categorySearch,
						platformSearch: platformSearch,
						countrySearch: countrySearch,
						citySearch: citySearch,
						stateSearch: stateSearch,
						followersRange: followersRange,
						itemsSoldRange: itemsSoldRange,
						viewDenouncements: viewDenouncements,
						viewDenouncementItems: viewDenouncementItems,
						viewAllowedItems: viewAllowedItems,
						viewTagIDItems: viewTagIDItems,
						dateRangeSeek: dateRangeSeek,
					},
				})
			);
		} else if (currentURL.includes('notificacoes')) {
			dispatch(
				updateNotificationsFilters({
					filterName: nameFilter,
					filterData: {
						titleSearch: titleSearch,
						sellerSearch: sellerSearch,
						permalinkSearch: permalinkSearch,
						offerIDSearch: offerIDSearch,
						tagsID: tagsID,
						saveTags: existentTag,
						orLogic: orLogic,
						sourceSearch: sourceSearch,
						denouncerSearch: denouncerSearch,
						classficationTypesID: classficationTypesID,
						classficationType: classficationType,
						platformSearch: platformSearch,
						countrySearch: countrySearch,
						citySearch: citySearch,
						stateSearch: stateSearch,
						dateRangeSearch: dateRangeSearch,
					},
				})
			);
		} else if (currentURL.includes('autores')) {
			dispatch(
				updateAuthorsFilters({
					filterName: nameFilter,
					filterData: {
						nameSearch: nameSearch,
						idAuthorSearch: idAuthorSearch,
						saveTags: existentTag,
						tagsID: tagsID,
						platformSearch: platformSearch,
						titleSearch: titleSearch,
						countrySearch: countrySearch,
						citySearch: citySearch,
						stateSearch: stateSearch,
					},
				})
			);
		}
	};

	//Function that clears the data from the inputs in the component. Default value is null
	const handleClearHook = (value) => {
		switch (value) {
			case 'nameSearch':
				setNameSearch('');
				setNameSearchValue('');
				break;
			case 'priceSearch':
				setPriceSelect({ start: 0, end: 999999999 });
				break;
			case 'author.authorData.followers.value':
				setFollowersRange({ start: 0, end: 999999999 });
				break;
			case 'offerData.items_sold.value':
				setItemsSoldRange({ start: 0, end: 999999999 });
				break;
			case 'titleSearch':
				setTitleSearch('');
				setTitleSearchValue('');
				break;
			case 'observationSearch':
				setObservationSearch('');
				setObservationSearchValue('');
				break;
			case 'anatelSearch':
				setAnatelSearch('');
				setAnatelSearchValue('');
				break;
			case 'sellerSearch':
				setSellerSearch('');
				setSellerSearchValue('');
				break;
			case 'permalinkSearch':
				setPermalinkSearch('');
				setPermalinkSearchValue('');
				break;
			case 'offerIDSearch':
				setOfferIDSearch('');
				setOfferIDSearchValue('');
				break;
			case 'tagSearch':
				setTagsID([]);
				setExistentTag([]);
				setSaveTagID('');
				setTagSearch('');
				setReloadTagSearch(reloadTagSearch + 1);
				break;
			case 'denouncementIDSearch':
				setSaveDenouncementID('');
				setDenouncementSearch('');
				setDenouncementSearchValue('');
				setReloadDenouncementSearch(reloadDenouncementSearch + 1);
				break;
			case 'classificationTypeSearch':
				setClassficationType([]);
				setClassficationTypesID([]);
				break;
			case 'denouncements':
				setViewDenouncementItems(false);
				setViewDenouncements('');
				break;
			case 'selectFilter':
				setfilterResetAutocomplete('');
				break;
			default:
				setClassficationType([]);
				setClassficationTypesID([]);
				setDenouncementSearch('');
				setDenouncementSearchValue('');
				setExistentTag([]);
				setNameSearch('');
				setNameSearchValue('');
				setPriceSelect({ start: 0, end: 999999999 });
				setReloadDenouncementSearch(reloadDenouncementSearch + 1);
				setReloadTagSearch(reloadTagSearch + 1);
				setSaveDenouncementID('');
				setSaveTagID('');
				setSellerSearch('');
				setSellerSearchValue('');
				setPermalinkSearch('');
				setPermalinkSearchValue('');
				setOfferIDSearch('');
				setOfferIDSearchValue('');
				setTagSearch('');
				setTagsID([]);
				setTitleSearch('');
				setTitleSearchValue('');
				setAnatelSearch('');
				setAnatelSearchValue('');
				setObservationSearch('');
				setObservationSearchValue('');
				setDenouncements('');
				setSelectedUser('');
				setNameFilter('');
				setFollowersRange({ start: 0, end: 999999999 });
				setItemsSoldRange({ start: 0, end: 999999999 });
				setViewDenouncementItems(false);
				setViewDenouncements('');
				setViewAllowedItems(false);
				setViewTagIDItems(false);
				break;
		}
	};

	//Default query for ReactiveSearch to perform searches in the component.
	const selectClientQuery = () => {
		return {
			query: {
				term: { 'offerClassification.clientID': selectedClient?.id },
			},
		};
	};

	/* 
		The addListSearchTags function maps TagSelector component's tag array
		every time a tag is added or removed.
	*/
	const addListSearchTags = (tags) => {
		setExistentTag(tags);
		setTagsID(
			tags.map(function (tag) {
				return tag.tagID;
			})
		);
	};

	//Function that performs state change in the ClassificationType input.
	const handleChangeClassficationType = (value: any) => {
		setClassficationType(value);
		setClassficationTypesID(
			value.map(function (value) {
				return value;
			})
		);
	};

	//Generic function that manages state changes in each of the components.
	const handleGenericTextChange = (
		event,
		setGenericSearchValue,
		setGenericSearch
	) => {
		setGenericSearchValue(event.target.value);
		if (event.target.value === '') {
			setGenericSearch('');
		}
	};

	const handleGenericByKeyPress = (
		event,
		setGenericSearch,
		genericSearchValue
	) => {
		if (event.keyCode === 13) {
			setGenericSearch(genericSearchValue);
		}
	};

	const handleGenericSearch = (setGenericSearch, genericSearchValue) => {
		setGenericSearch(genericSearchValue);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const renderSelectedFilter = () => (
		<>
			<FormDivider
				name={t('FilterBar.Salvar filtros')}
				margin="8px 0"
				opacity={0.8}
				background={
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.background
				}
				color={selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''}
			/>
			<TextField
				variant="standard"
				data-testid="selectSaveFilters"
				placeholder={t('FilterBar.Salvar grupo de filtros')}
				onChange={(event) => setNameFilter(event.target.value)}
				size="small"
				value={nameFilter}
				style={{
					width: '100%',
					height: 40,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
					borderRadius: 4,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.id === 'dark' && '#575757'}`
							: 'none',
					boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
				}}
				InputProps={{
					disableUnderline: true,
					style: {
						height: 40,
						paddingLeft: 15,
						marginTop: 5,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
					endAdornment: (
						<OfferToolTip
							title={t('FilterBar.Salvar grupo')}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									style={{
										height: 28,
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: '',
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									data-testid="saveFilters"
									onClick={() => {
										if (nameFilter.trim() !== '') {
											saveDefaultFilters(nameFilter);
											setNameFilter('');
											enqueueSnackbar(t('FilterBar.Grupo salvo com sucesso'), {
												variant: 'success',
											});
										} else {
											enqueueSnackbar(
												t(
													'FilterBar.Dê um nome para seu grupo e tente novamente.'
												),
												{ variant: 'warning' }
											);
										}
									}}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<Save />
								</IconButton>
							</InputAdornment>
						</OfferToolTip>
					),
				}}
			/>
			<Autocomplete
				id="selectFilter"
				data-testid="selectFilter"
				classes={{
					option: classes.autoCompleteStyle,
					paper: classes.autoCompleteStyle,
				}}
				sx={{
					width: '100%',
					'& .MuiSvgIcon-root': {
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor:
							selectedTheme.id === 'dark' ? selectedTheme.border : '',
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					},
				}}
				options={Object.keys(filtersRedux[filterType])}
				onChange={(_, newValue) => {
					if (newValue) {
						setFilterRedux(filtersRedux[filterType][newValue]);
						setFilterState(filtersRedux[filterType][newValue]);
					}
				}}
				value={filterResetAutocomplete}
				disableClearable
				autoHighlight
				autoComplete
				autoSelect
				selectOnFocus
				getOptionLabel={(option) => (option === 'defaultFilters' ? '' : option)}
				renderOption={(props, option) => (
					<li
						{...props}
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<>
							{option === 'defaultFilters' ? t('FilterBar.Sem filtro') : option}
						</>
						{option !== 'defaultFilters' ? (
							<OfferToolTip
								title={t('FilterBar.Apagar esse grupo')}
								aria-label="inativo"
								enterDelay={700}
								enterNextDelay={700}
								arrow
								TransitionComponent={Zoom}
							>
								<IconButton
									aria-label="Deletefilter"
									data-testid="Deletfilter"
									onClick={(event) => {
										event.stopPropagation();
										dispatch(
											removeSpecificFilter({
												filterType: filterType,
												filterName: option,
											})
										);
									}}
									size="small"
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorMedium
												: selectedTheme.primary,
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<CloseRounded />
								</IconButton>
							</OfferToolTip>
						) : null}
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						data-testid="selectFilter-textField"
						placeholder={t('FilterBar.Carregar grupo de filtros')}
						variant="outlined"
						InputProps={{
							style: {
								height: 40,
								paddingLeft: 12,
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
								borderRadius: 4,
							},
							...params.InputProps,
						}}
						InputLabelProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						sx={{
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay3dp
									: selectedTheme.foreground,
							borderRadius: '4px',
							overflow: 'hidden',
						}}
					/>
				)}
			/>
		</>
	);

	//Function that renders the hidden components of ReactiveSearch.
	const reactiveSearchHiddenComponents = () => (
		<>
			{nameSearchField ? (
				<DataSearch
					title={`${t('FilterBar.Busca por nome')}:`}
					componentId="nameSearch"
					placeholder={t('FilterBar.Nome')}
					value={nameSearch}
					dataField={nameSearchField}
					showIcon={false}
					autosuggest={false}
					highlight={false}
					fuzziness="AUTO"
					queryFormat="and"
					debounce={1}
					filterLabel={t('FilterBar.Título')}
					style={{ display: 'none' }}
				/>
			) : null}
			{titleSearchField ? (
				<DataSearch
					title={`${t('FilterBar.Busca por título')}:`}
					componentId="titleSearch"
					placeholder={t('FilterBar.Título do anúncio')}
					value={titleSearch}
					dataField={titleSearchField}
					showIcon={false}
					autosuggest={false}
					highlight={false}
					fuzziness="AUTO"
					queryFormat="and"
					debounce={1}
					filterLabel={t('FilterBar.Título')}
					style={{ display: 'none' }}
				/>
			) : null}
			{observationSearchField ? (
				<DataSearch
					title={`${t('FilterBar.Observações')}:`}
					componentId="observationSearch"
					placeholder={t('FilterBar.Observações')}
					value={observationSearch}
					dataField={observationSearchField}
					showIcon={false}
					autosuggest={false}
					highlight={false}
					fuzziness="AUTO"
					queryFormat="and"
					debounce={1}
					filterLabel={t('FilterBar.Observações')}
					style={{ display: 'none' }}
				/>
			) : null}
			{anatelSearchField ? (
				<DataSearch
					title={`${t('FilterBar.Busca por número anatel')}:`}
					componentId="anatelSearch"
					placeholder={t('FilterBar.Número anatel')}
					value={anatelSearch}
					dataField={anatelSearchField}
					showIcon={false}
					autosuggest={false}
					highlight={false}
					fuzziness="AUTO"
					queryFormat="and"
					debounce={1}
					filterLabel={t('FilterBar.Número anatel')}
					style={{ display: 'none' }}
				/>
			) : null}
			{authorNameField ? (
				<DataSearch
					title={`${t('FilterBar.Busca por autor')}:`}
					componentId="sellerSearch"
					placeholder={t('FilterBar.Autor')}
					value={sellerSearch}
					dataField={authorNameField}
					showIcon={false}
					autosuggest
					highlight={false}
					fuzziness="AUTO"
					queryFormat="or"
					debounce={1}
					filterLabel={t('FilterBar.Autor')}
					style={{ display: 'none' }}
				/>
			) : null}
			{permalinkSearchField ? (
				<DataSearch
					title={`${t('FilterBar.Busca por url')}:`}
					componentId="permalinkSearch"
					placeholder={t('FilterBar.URL da publicação')}
					value={permalinkSearch}
					dataField={permalinkSearchField}
					showIcon={false}
					highlight={false}
					fuzziness={1}
					debounce={1}
					queryFormat="and"
					filterLabel={t('FilterBar.URL da publicação')}
					style={{ display: 'none' }}
				/>
			) : null}
			{offerIDSearchField ? (
				<DataSearch
					title={`${t('FilterBar.Busca por ID')}:`}
					componentId="offerIDSearch"
					placeholder={t('FilterBar.ID da oferta')}
					value={offerIDSearch}
					dataField={offerIDSearchField}
					showIcon={false}
					highlight={false}
					fuzziness={0}
					debounce={1}
					filterLabel={t('FilterBar.ID da oferta')}
					style={{ display: 'none' }}
				/>
			) : null}
			{denouncementIDField ? (
				<DataSearch
					title={`${t('FilterBar.Denouncement ID')}:`}
					componentId="denouncementIDSearch"
					placeholder={t('FilterBar.Denouncement ID')}
					value={denouncementSearch}
					dataField={denouncementIDField}
					showIcon={false}
					autosuggest={false}
					highlight={false}
					fuzziness={0}
					queryFormat="or"
					debounce={1}
					filterLabel={t('FilterBar.ID')}
					style={{ display: 'none' }}
				/>
			) : null}
			{tagSearchField ? (
				<DataSearch
					title={`${t('FilterBar.Busca por tag')}:`}
					componentId="tagSearch"
					placeholder={t('FilterBar.Chave de busca')}
					value={tagSearch}
					dataField={tagSearchField}
					showIcon={false}
					autosuggest={false}
					highlight={false}
					fuzziness={0}
					queryFormat="and"
					debounce={1}
					filterLabel={t('FilterBar.Tag')}
					style={{ display: 'none' }}
				/>
			) : null}
			{tagSelectorField ? (
				<MultiDropdownList
					key={keyLogic}
					componentId="tagsSelect"
					dataField={tagSelectorField}
					value={tagsID}
					size={1000000}
					queryFormat={orLogic ? 'or' : 'and'}
					showCount={false}
					showSearch
					showFilter
					filterLabel={`${t('FilterBar.Etiquetas')}`}
					URLParams={false}
					style={{ display: 'none' }}
				/>
			) : null}
			{classificationTypeField ? (
				<MultiDropdownList
					componentId="classificationTypeSearch"
					dataField={classificationTypeField}
					value={classficationTypesID}
					size={1000000}
					queryFormat="or"
					showCount={false}
					showSearch
					showFilter
					filterLabel={`${t('FilterBar.Motivos de denúncia')}`}
					URLParams={false}
					style={{ display: 'none' }}
				/>
			) : null}
		</>
	);

	//Function that renders the other visible components in the filter bar.
	const contentDrawer = () => (
		<Column
			style={{
				rowGap: 10,
			}}
		>
			{reactiveSearchHiddenComponents()}
			<Line
				style={{
					width: '100%',
					justifyContent: 'space-between',
					marginRight: -4,
				}}
			>
				<Typography
					variant="subtitle2"
					style={{
						fontWeight: 'bold',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: '#4F4F4F',
					}}
				>
					{t('FilterBar.Filtros avançados')}:
				</Typography>
				<IconButton
					onClick={() => dispatch(changePanelState(''))}
					size="small"
					style={{ marginRight: -5 }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<KeyboardBackspace
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: '#4F4F4F',
						}}
					/>
				</IconButton>
			</Line>
			{nameSearchField ? (
				<TextField
					variant="standard"
					data-testid="nameSearchValue"
					size="small"
					value={nameSearchValue}
					placeholder={t('FilterBar.Nome')}
					onChange={(event) =>
						handleGenericTextChange(event, setNameSearchValue, setNameSearch)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(event, setNameSearch, nameSearchValue)
					}
					style={{
						width: '100%',
						height: 40,
						borderRadius: 4,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									style={{
										height: 28,
										background:
											selectedTheme.id === 'dark' && selectedTheme.footerLine,
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(setNameSearch, nameSearchValue)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}
			{titleSearchField ? (
				<TextField
					variant="standard"
					data-testid="titleSearchValue"
					size="small"
					placeholder={t('FilterBar.Título do anúncio')}
					value={titleSearchValue}
					onChange={(event) =>
						handleGenericTextChange(event, setTitleSearchValue, setTitleSearch)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(event, setTitleSearch, titleSearchValue)
					}
					style={{
						width: '100%',
						height: 40,
						borderRadius: 4,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									sx={{
										height: 28,
										background:
											selectedTheme.id === 'dark' && selectedTheme.footerLine,
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(setTitleSearch, titleSearchValue)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}

			{permalinkSearchField ? (
				<TextField
					variant="standard"
					value={permalinkSearchValue}
					data-testid="permalinkSearchValue"
					size="small"
					placeholder={t('FilterBar.URL da publicação')}
					onChange={(event) =>
						handleGenericTextChange(
							event,
							setPermalinkSearchValue,
							setPermalinkSearch
						)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(
							event,
							setPermalinkSearch,
							permalinkSearchValue
						)
					}
					style={{
						width: '100%',
						height: 40,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						borderRadius: 4,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									style={{
										height: 28,
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: '',
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(
											setPermalinkSearch,
											permalinkSearchValue
										)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}
			{permalinkSearchField ? (
				<TextField
					variant="standard"
					value={offerIDSearchValue}
					data-testid="offerIDSearchValue"
					size="small"
					placeholder={t('FilterBar.ID da oferta')}
					onChange={(event) =>
						handleGenericTextChange(
							event,
							setOfferIDSearchValue,
							setOfferIDSearch
						)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(event, setOfferIDSearch, offerIDSearchValue)
					}
					style={{
						width: '100%',
						height: 40,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						borderRadius: 4,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									style={{
										height: 28,
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: '',
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(setOfferIDSearch, offerIDSearchValue)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}
			{authorNameField ? (
				<TextField
					variant="standard"
					value={sellerSearchValue}
					data-testid="sellerSearchValue"
					size="small"
					placeholder={t('FilterBar.Autor')}
					onChange={(event) =>
						handleGenericTextChange(
							event,
							setSellerSearchValue,
							setSellerSearch
						)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(event, setSellerSearch, sellerSearchValue)
					}
					style={{
						width: '100%',
						height: 40,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						borderRadius: 4,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									style={{
										height: 28,
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: '',
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(setSellerSearch, sellerSearchValue)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}
			{denouncementIDField ? (
				<TextField
					variant="standard"
					size="small"
					data-testid="denouncementSearchValue"
					value={denouncementSearchValue}
					placeholder={t('FilterBar.Chave de Denúncia')}
					onChange={(event) =>
						handleGenericTextChange(
							event,
							setDenouncementSearchValue,
							setDenouncementSearch
						)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(
							event,
							setDenouncementSearch,
							denouncementSearchValue
						)
					}
					style={{
						width: '100%',
						height: 40,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						borderRadius: 4,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									style={{
										height: 28,
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: '',
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(
											setDenouncementSearch,
											denouncementSearchValue
										)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}
			{tagSearchField ? (
				<TextField
					defaultValue={saveTagID}
					key={reloadTagSearch}
					variant="standard"
					data-testid="tagSearchField"
					size="small"
					placeholder={t('FilterBar.Chave de busca')}
					onChange={(event) =>
						handleGenericTextChange(event, setTagSearchValue, setTagSearch)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(event, setTagSearch, tagSearchValue)
					}
					style={{
						width: '100%',
						height: 40,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						borderRadius: 4,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									style={{
										height: 28,
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: '',
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(setTagSearch, tagSearchValue)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}
			{anatelSearchField ? (
				<TextField
					variant="standard"
					data-testid="anatelSearchValue"
					size="small"
					placeholder={t('FilterBar.Número anatel')}
					value={anatelSearchValue}
					onChange={(event) =>
						handleGenericTextChange(
							event,
							setAnatelSearchValue,
							setAnatelSearch
						)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(event, setAnatelSearch, anatelSearchValue)
					}
					style={{
						width: '100%',
						height: 40,
						borderRadius: 4,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						inputComponent: NumericFormat as any,
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									sx={{
										height: 28,
										background:
											selectedTheme.id === 'dark' && selectedTheme.footerLine,
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(setAnatelSearch, anatelSearchValue)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}
			{observationSearchField ? (
				<TextField
					variant="standard"
					data-testid="observationSearchValue"
					size="small"
					placeholder={t('FilterBar.Observações')}
					value={observationSearchValue}
					onChange={(event) =>
						handleGenericTextChange(
							event,
							setObservationSearchValue,
							setObservationSearch
						)
					}
					onKeyDown={(event) =>
						handleGenericByKeyPress(
							event,
							setObservationSearch,
							observationSearchValue
						)
					}
					style={{
						width: '100%',
						height: 40,
						borderRadius: 4,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme.overlay3dp
								: selectedTheme.foreground,
						border: selectedTheme.id === 'dark' ? `1px solid #575757` : 'none',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							height: 40,
							paddingLeft: 15,
							marginTop: 5,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
							borderRadius: 4,
						},
						endAdornment: (
							<InputAdornment position="end" style={{ marginTop: -5 }}>
								<Divider
									sx={{
										height: 28,
										background:
											selectedTheme.id === 'dark' && selectedTheme.footerLine,
									}}
									orientation="vertical"
								/>
								<IconButton
									size="small"
									onClick={() =>
										handleGenericSearch(
											setObservationSearch,
											observationSearchValue
										)
									}
									style={{
										padding: 7,
										borderRadius: '0px 5px 5px 0px ',
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
								>
									<ChevronRightRounded
										style={{
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : null}

			{dateRangeField ? (
				<>
					<FormDivider
						name={t('FilterBar.Período')}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay8dp
								: selectedTheme.background
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<DateRange
						componentId="dateRangeSearch"
						data-testid="dateRangeSearch"
						dataField={dateRangeField}
						placeholder={{
							start: t('FilterBar.Data inicial'),
							end: t('FilterBar.Data Final'),
						}}
						dayPickerInputProps={{
							formatDate,
							format: dateFormat,
							parseDate,
						}}
						value={dateRangeSearch}
						onChange={setDateRangeSearch}
						focused={false}
						numberOfMonths={1}
						queryFormat="date"
						react={{
							and: [
								'tagsSelect',
								'observationSearch',
								'titleSearch',
								'sellerSearch',
								'classificationTypeSearch',
								'sourceSearch',
								'platformSearch',
								'denouncerSearch',
								'countrySearch',
								'stateSearch',
								'citySearch',
							],
						}}
						autoFocusEnd={true}
						showClear={true}
						showFilter={true}
						filterLabel="Date"
						URLParams={false}
					/>
				</>
			) : null}
			{dateRangeSeekField ? (
				<>
					<FormDivider
						name={t('FilterBar.Período')}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay8dp
								: selectedTheme.background
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<DateRange
						componentId="dateRangeSeek"
						data-testid="dateRangeSeek"
						dataField={dateRangeSeekField}
						placeholder={{
							start: t('FilterBar.Data inicial'),
							end: t('FilterBar.Data Final'),
						}}
						dayPickerInputProps={{
							formatDate,
							format: dateFormat,
							parseDate,
						}}
						value={dateRangeSeek}
						onChange={setDateRangeSeek}
						focused={false}
						numberOfMonths={1}
						queryFormat="date"
						react={{
							and: [
								'tagsSelect',
								'observationSearch',
								'titleSearch',
								'anatelSearch',
								'sellerSearch',
								'classificationTypeSearch',
								'sourceSearch',
								'platformSearch',
								'denouncerSearch',
								'countrySearch',
								'stateSearch',
								'citySearch',
							],
						}}
						autoFocusEnd={true}
						showClear={true}
						showFilter={true}
						filterLabel="Date"
						URLParams={false}
					/>
				</>
			) : null}
			{tagSelectorField ? (
				<>
					<Line>
						<FormDivider
							name={`${t('FilterBar.Etiquetas')}`}
							margin="8px 0"
							opacity={0.8}
							background={
								selectedTheme.id === 'dark'
									? selectedTheme.overlay8dp
									: selectedTheme.background
							}
							color={
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
							}
						/>
						<OfferToolTip
							title={t('FilterBar.Lógica para filtragem das tags')}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<IconButton
								color="primary"
								size="small"
								onClick={handleChangeLogic}
								style={{
									height: 18,
									width: 25,
									borderRadius: 4,
									borderWidth: 1,
									borderStyle: 'solid',
									borderColor: selectedTheme.primary,
									marginLeft: 10,
								}}
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<Typography
									variant="caption"
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.primaryDark,
										marginBottom: -1,
									}}
								>
									{orLogic ? 'OU' : 'E'}
								</Typography>
							</IconButton>
						</OfferToolTip>
					</Line>
					<TagSelector
						dataArray={existentTag}
						setDataArray={addListSearchTags}
						suggestions={tagsActiveClient}
						size="small"
						handleSaveTags={false}
						width="310px"
						rows={3}
						limitTags={4}
						loadingText={t('Carregando...')}
					/>
				</>
			) : null}
			{denounceFilterField ? (
				<>
					<FormDivider
						name={`${t('FilterBar.Denunciante')}`}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay8dp
								: selectedTheme.background
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>

					<DenounceUserFilter
						keyLogic={keyLogic}
						selectedUser={selectedUser}
						setSelectedUser={setSelectedUser}
						denouncements={denouncements}
						setDenouncements={setDenouncements}
					/>
				</>
			) : null}

			{denouncementReasonField ? (
				<>
					<FormDivider
						name={t('FilterBar.Motivos de denúncia')}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay8dp
								: selectedTheme.background
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<Autocomplete
						id="SearchDenouncementReason"
						value={[...classficationType]}
						size={'small'}
						loading={loading}
						data-testid="SearchDenouncementReason"
						loadingText={t('Carregando...')}
						multiple
						autoComplete
						disableCloseOnSelect
						autoHighlight
						clearOnBlur
						clearOnEscape
						includeInputInList
						disableClearable
						forcePopupIcon={false}
						filterSelectedOptions
						options={classificationTypes.map((el) => el.id as string)}
						getOptionLabel={(option) => t(`ClassificationType.${option}`)}
						renderOption={(props, option) => (
							<li {...props}>{t(`ClassificationType.${option}`)}</li>
						)}
						freeSolo={false}
						onChange={(_, newValue) => {
							handleChangeClassficationType(newValue);
						}}
						classes={{
							option: classes.autoCompleteStyle,
							paper: classes.autoCompleteStyle,
						}}
						sx={{ width: '100%' }}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									{...getTagProps({ index })}
									key={index}
									label={t(`ClassificationType.${option}`)}
									variant="outlined"
									style={{
										maxWidth: '98%',
										height: 30,
										margin: 4,
										borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
										borderRadius: 6,
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
										fontSize: 15,
									}}
									deleteIcon={<CloseRounded />}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								inputProps={{
									...params.inputProps,
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
								placeholder={
									classficationType.length < 1
										? t('FilterBar.Busca por motivos')
										: ''
								}
								InputLabelProps={{
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
								}}
								sx={{
									'& .MuiInputBase-root': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								}}
							/>
						)}
					/>
				</>
			) : null}

			<ViewDenouncementItems
				checkboxDenouncementsItemsField={checkboxDenouncementsItemsField}
				checkboxTagIDItemsField={checkboxTagIDItemsField}
				checkboxAllowedItemsField={checkboxAllowedItemsField}
				keyLogic={keyLogic}
				viewDenouncements={viewDenouncements}
				setViewDenouncements={setViewDenouncements}
				setViewDenouncementItems={setViewDenouncementItems}
				viewDenouncementItems={viewDenouncementItems}
				viewAllowedItems={viewAllowedItems}
				setViewAllowedItems={setViewAllowedItems}
				viewTagIDItems={viewTagIDItems}
				setViewTagIDItems={setViewTagIDItems}
			/>

			<FormDivider
				name=""
				margin="8px 0"
				opacity={0.8}
				background={
					selectedTheme.id === 'dark' ? '#303030' : selectedTheme.background
				}
			/>
			{idAuthorSearchField ? (
				<MultiDropdownList
					data-testid="idSearchField"
					componentId="idAuthorSearch"
					dataField={idAuthorSearchField}
					size={1000}
					queryFormat="or"
					showCount={false}
					showSearch
					value={idAuthorSearch}
					onChange={setAuthorIdSearch}
					placeholder={`${t('FilterBar.Autor ID')}:`}
					searchPlaceholder={`${t('FilterBar.Busca')}:`}
					showFilter
					filterLabel={t('FilterBar.ID')}
					react={{
						and: [
							`${followersSearchField}`,
							'nameSearch',
							'platformSearch',
							'countrySearch',
							'citySearch',
							'sourceSearch',
						],
					}}
					URLParams={false}
					style={{
						width: '100%',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						padding: 0,
						borderRadius: 4,
					}}
				/>
			) : null}

			{sourceSearchField ? (
				<MultiDropdownList
					componentId="sourceSearch"
					dataField={sourceSearchField}
					data-testid="sourceSearchField"
					size={1000000}
					value={sourceSearch}
					onChange={setSourceSearch}
					queryFormat="or"
					showCount={false}
					showSearch
					placeholder={t('FilterBar.Fonte da Denúncia')}
					searchPlaceholder={`${t('FilterBar.Busca')}:`}
					showFilter
					filterLabel={t('FilterBar.Fonte da Denúncia')}
					react={{
						and: [
							`${itemsSoldSearchField}`,
							`${followersSearchField}`,
							'tagsSelect',
							'observationSearch',
							'titleSearch',
							'anatelSearch',
							'sellerSearch',
							'dateRangeSearch',
							'classificationTypeSearch',
							'platformSearch',
							'denouncerSearch',
							'countrySearch',
							'stateSearch',
							'citySearch',
							'idAuthorSearch',
							'denouncementsUsers',
						],
					}}
					URLParams={false}
					style={{
						width: '100%',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						padding: 0,
						borderRadius: 4,
					}}
				/>
			) : null}
			{platformSearchField ? (
				<MultiDropdownList
					defaultValue={saveINcountryID}
					componentId="platformSearch"
					dataField={platformSearchField}
					data-testid="platformSearchField"
					size={1000000}
					value={platformSearch}
					onChange={setPlatformSearch}
					queryFormat="or"
					showCount={false}
					showSearch
					placeholder={t('FilterBar.Plataforma')}
					searchPlaceholder={`${t('FilterBar.Busca')}:`}
					showFilter
					filterLabel={t('FilterBar.Plataforma')}
					react={{
						and: [
							`${itemsSoldSearchField}`,
							`${followersSearchField}`,
							'tagsSelect',
							'observationSearch',
							'titleSearch',
							'anatelSearch',
							'sellerSearch',
							'dateRangeSearch',
							'classificationTypeSearch',
							'sourceSearch',
							'denouncerSearch',
							'countrySearch',
							'stateSearch',
							'citySearch',
							'idAuthorSearch',
							'denouncementsUsers',
						],
					}}
					URLParams={false}
					style={{
						width: '100%',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						padding: 0,
						borderRadius: 4,
					}}
				/>
			) : null}
			{denouncerSearchField ? (
				<MultiDropdownList
					componentId="denouncerSearch"
					dataField={denouncerSearchField}
					data-testid="denouncerSearchField"
					size={1000000}
					queryFormat="or"
					value={denouncerSearch}
					onChange={setDenouncerSearch}
					showCount={false}
					showSearch
					defaultQuery={selectClientQuery}
					placeholder={t('FilterBar.Denunciante')}
					searchPlaceholder={`${t('FilterBar.Busca')}:`}
					showFilter
					filterLabel={t('FilterBar.Denunciante')}
					react={{
						and: [
							`${itemsSoldSearchField}`,
							`${followersSearchField}`,
							'tagsSelect',
							'observationSearch',
							'titleSearch',
							'anatelSearch',
							'sellerSearch',
							'dateRangeSearch',
							'classificationTypeSearch',
							'sourceSearch',
							'platformSearch',
							'countrySearch',
							'stateSearch',
							'citySearch',
							'idAuthorSearch',
						],
					}}
					URLParams={false}
					style={{
						width: '100%',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						padding: 0,
						borderRadius: 4,
					}}
				/>
			) : null}
			{observationSearchField ? (
				<MultiDropdownList
					componentId="observationSearch"
					data-testid="observationSearchField"
					dataField={observationSearchField}
					size={1000000}
					queryFormat="or"
					defaultQuery={selectClientQuery}
					showCount={false}
					showSearch
					placeholder={t('FilterBar.Observações')}
					searchPlaceholder={`${t('FilterBar.Busca')}:`}
					showFilter
					filterLabel={t('FilterBar.Observações')}
					react={{
						and: [
							`${itemsSoldSearchField}`,
							`${followersSearchField}`,
							'tagsSelect',
							'citySearch',
							'titleSearch',
							'anatelSearch',
							'sellerSearch',
							'dateRangeSearch',
							'classificationTypeSearch',
							'sourceSearch',
							'platformSearch',
							'denouncerSearch',
							'countrySearch',
							'stateSearch',
						],
					}}
					URLParams={false}
					style={{
						width: '100%',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						padding: 0,
						borderRadius: 4,
					}}
				/>
			) : null}
			{categorySearchField ? (
				<MultiDropdownList
					componentId="categorySearch"
					dataField={categorySearchField}
					data-testid="categorySearchField"
					size={10000}
					queryFormat="or"
					value={categorySearch}
					onChange={setCategorySearch}
					showCount
					showSearch
					placeholder={`${t('FilterBar.Categoria')}:`}
					searchPlaceholder={`${t('FilterBar.Busca')}:`}
					showFilter
					filterLabel={t('FilterBar.Categoria')}
					react={{
						and: [
							`${itemsSoldSearchField}`,
							`${followersSearchField}`,
							'tagsSelect',
							'searchbox',
							'tagSearch',
							'titleSearch',
							'anatelSearch',
							'sellerSearch',
							'citySearch',
							'stateSearch',
							'idAuthorSearch',
							'countrySearch',
							'platformSearch',
							'sourceSearch',
							'denouncementIDSearch',
							'denouncements',
							'denouncementsUsers',
						],
					}}
					URLParams={false}
					style={{
						width: '100%',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						padding: 0,
						borderRadius: 4,
					}}
				/>
			) : null}
			{priceSliderField ? (
				<>
					<Line>
						<FormDivider
							name={t('FilterBar.Faixa de preço')}
							margin="8px 0"
							opacity={0.8}
							background={
								selectedTheme.id === 'dark'
									? selectedTheme.overlay8dp
									: selectedTheme.background
							}
							color={
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
							}
						/>
					</Line>
					<RangeSelectorPrice
						componentId="priceSearch"
						dataField="offerData.price.value"
						label="PriceSlider.Faixa de Preço"
						rangeValues={priceSelect}
						setRangeValues={setPriceSelect}
					/>
				</>
			) : null}
			{followers ? (
				<>
					<FormDivider
						name={t('FilterBar.Seguidores')}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay8dp
								: selectedTheme.background
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<RangeSelector
						dataField={followersSearchField}
						label="Seguidores"
						rangeValues={followersRange}
						setRangeValues={setFollowersRange}
					/>
				</>
			) : null}
			{itemsSold ? (
				<>
					<FormDivider
						name={t('FilterBar.Itens vendidos')}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay8dp
								: selectedTheme.background
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<RangeSelector
						dataField={itemsSoldSearchField}
						label="Itens vendidos"
						rangeValues={itemsSoldRange}
						setRangeValues={setItemsSoldRange}
					/>
				</>
			) : null}
			{citySearchField ? (
				<>
					<FormDivider
						name={t('FilterBar.Localidade')}
						margin="8px 0"
						opacity={0.8}
						background={
							selectedTheme.id === 'dark'
								? selectedTheme.overlay8dp
								: selectedTheme.background
						}
						color={
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : ''
						}
					/>
					<MultiDropdownList
						componentId="citySearch"
						data-testid="citySearchField"
						dataField={citySearchField}
						size={1000000}
						queryFormat="or"
						showCount={false}
						value={citySearch}
						onChange={setCitySearch}
						showSearch
						placeholder={`${t('FilterBar.Cidade')}:`}
						searchPlaceholder={`${t('FilterBar.Busca')}:`}
						showFilter
						filterLabel={t('FilterBar.Cidade')}
						react={{
							and: [
								'tagsSelect',
								'observationSearch',
								'titleSearch',
								'anatelSearch',
								'sellerSearch',
								'dateRangeSearch',
								'classificationTypeSearch',
								'sourceSearch',
								'platformSearch',
								'denouncerSearch',
								'countrySearch',
								'stateSearch',
								'idAuthorSearch',
								'denouncementsUsers',
							],
						}}
						URLParams={false}
						style={{
							width: '100%',
							boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
							padding: 0,
							borderRadius: 4,
						}}
					/>
				</>
			) : null}
			{countrySearchField ? (
				<MultiDropdownList
					data-testid="citySearchField"
					componentId="citySearchField"
					dataField={countrySearchField}
					size={1000}
					queryFormat="or"
					value={countrySearch}
					onChange={setCountrySearch}
					showCount={false}
					showSearch
					placeholder={`${t('FilterBar.País')}:`}
					searchPlaceholder={`${t('FilterBar.Busca')}:`}
					showFilter
					filterLabel={t('FilterBar.País')}
					react={{
						and: [
							`${itemsSoldSearchField}`,
							`${followersSearchField}`,
							'tagsSelect',
							'observationSearch',
							'titleSearch',
							'anatelSearch',
							'sellerSearch',
							'dateRangeSearch',
							'classificationTypeSearch',
							'sourceSearch',
							'platformSearch',
							'denouncerSearch',
							'stateSearch',
							'citySearch',
							'idAuthorSearch',
							'denouncementsUsers',
						],
					}}
					URLParams={false}
					style={{
						width: '100%',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						padding: 0,
						borderRadius: 4,
					}}
				/>
			) : null}
			{stateSearchField ? (
				<MultiDropdownList
					data-testid="stateSearchField"
					componentId="stateSearch"
					dataField={stateSearchField}
					size={1000000}
					queryFormat="or"
					showCount={false}
					showSearch
					value={stateSearch}
					onChange={setStateSearch}
					placeholder={`${t('FilterBar.Estado')}:`}
					searchPlaceholder={`${t('FilterBar.Busca')}:`}
					showFilter
					filterLabel={t('FilterBar.Estado')}
					react={{
						and: [
							`${itemsSoldSearchField}`,
							`${followersSearchField}`,
							'tagsSelect',
							'observationSearch',
							'titleSearch',
							'anatelSearch',
							'sellerSearch',
							'dateRangeSearch',
							'classificationTypeSearch',
							'sourceSearch',
							'platformSearch',
							'denouncerSearch',
							'countrySearch',
							'citySearch',
							'idAuthorSearch',
							'denouncementsUsers',
						],
					}}
					URLParams={false}
					style={{
						width: '100%',
						boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
						padding: 0,
						borderRadius: 4,
					}}
				/>
			) : null}
			{/* {renderSelectedFilter()} */}
			<FormDivider
				name=""
				margin="8px 0"
				opacity={0.8}
				background={
					selectedTheme.id === 'dark' ? '#303030' : selectedTheme.background
				}
			/>
			<SelectedFilters
				showClearAll
				clearAllLabel={t('FilterBar.Limpar Filtros')}
				onClear={(value) => handleClearHook(value)}
			/>
		</Column>
	);

	return (
		<Slide
			in={panelControlList.panelControl !== '' ? true : false}
			direction="right"
			timeout={500}
		>
			<Column
				data-testid="filterBarOpen"
				onBlur={handleCloseOnBlur}
				onMouseOver={() => setMouseOver(true)}
				onMouseLeave={() => setMouseOver(false)}
				style={{
					width: 340,
					transition: 'transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					rowGap: 10,
					padding: '5px 15px 15px',
					position: 'absolute',
					left:
						vertMenuState.menuState === false
							? drawerWidth - 1
							: drawerWidthOpen - 1,
					top: '63px',
					borderRadius: '0 6px 6px 0',
					border: '1px solid',
					borderColor:
						selectedTheme.id === 'dark' ? selectedTheme.foreground : '#e9e9e9',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay8dp
							: selectedTheme.background,
					filter: 'brightness(1.03)',
					boxShadow: 'rgba(0, 0, 0, 0.2) 3px -2px 9px 1px',
					zIndex: 19,
					overflow: 'auto',
					height: 'auto',
				}}
			>
				{contentDrawer()}
			</Column>
		</Slide>
	);
};

export default FilterBarComponent;
