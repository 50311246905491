import React, { useEffect, useState } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { CloudDownload } from '@mui/icons-material/';
import { makeStyles } from '@mui/styles';
import { api, setupRequestToken } from '../../utils/api';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { useAuth0 } from '../../react-auth0-spa';
import { useDispatch } from 'react-redux';
import { useOfferPlatformData } from '../../hooks/OfferPlatformData';
import { usePO } from '../../utils/POContext';
import { useTranslation } from 'react-i18next';
import Removed from './Removed';
import ToApply from './ToApply';
import Unauthorized from '../Page401';
import UnderAnalysisTab from './UnderAnalysis';
import WindowTabs from '../../components/WindowTabs';
import FilterBarComponent from '../../components/FilterBar';
// import FinalizedTab from './Finalized/FinalizedDenoucementTable';
import RadialMenu from '../../components/RadialMenu';
import { getNotificationsSpreadsheetFile } from '../../services/notifications';
import { enqueueSnackbar } from 'notistack';

const Notificacoes: React.FC = () => {
	const { selectedClient, selectedTheme, userData, updateCursorMode } = usePO();
	const { platformINcountry } = useOfferPlatformData();
	const { t } = useTranslation();

	const { token } = useAuth0();
	setupRequestToken(api, token);

	const bearerToken = `Bearer ${token}`;
	const elasticProxyUrl = `${process.env.REACT_APP_API_BASE_URL}/offerClassification`;

	const [selectedPlatform, setSelectedPlatform] = useState('*');
	const [requestBody, setRequestBody] = useState({});
	const [, setLoading] = useState(false);

	const dispatch = useDispatch();

	const useStyles = makeStyles(() => ({
		menuItem: {
			backgroundColor:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay4dp
					: selectedTheme.foreground,
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',

			'&:focus': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground,
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.disabled,
			},
			'& .MuiSvgIcon-root': {
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor:
					selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor:
					selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor:
					selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
			},
		},
	}));

	const classes = useStyles();

	useEffect(() => {
		dispatch(changeActiveMenu('notificacoes'));
	}, [dispatch]);

	const handlePlatformChange = (event: any) => {
		setSelectedPlatform(event.target.value);
	};

	const menuItem1Icon = (
		<CloudDownload
			style={{
				fontSize: 22,
				marginTop: -3,
				color: 'white',
			}}
		/>
	);

	const handleDownload = async () => {
		setLoading(true);
		updateCursorMode('wait');
		enqueueSnackbar(
			t(
				'Classificações.O download iniciará automaticamente. Aguarde por favor.'
			),
			{ variant: 'info' }
		);
		const response: any = await getNotificationsSpreadsheetFile(requestBody);
		if (response?.success) {
			enqueueSnackbar(t(response.message), {
				variant: 'success',
			});
		} else {
			enqueueSnackbar(
				t(
					response?.message ||
						'Erro interno. Tente mais tarde ou contate o suporte'
				),
				{
					variant: 'error',
				}
			);
		}
		setLoading(false);
		updateCursorMode('default');
	};

	const menuData = [
		{
			icon: menuItem1Icon,
			action: handleDownload,
			tooltip: 'Classificações.Baixar planilha com os resultados da busca',
		},
	];

	const renderTab1 = () => <ToApply selectedPlatform={selectedPlatform} />;
	const renderTab2 = () => (
		<UnderAnalysisTab selectedPlatform={selectedPlatform} />
	);
	const renderTab3 = () => <Removed selectedPlatform={selectedPlatform} />;
	// const renderTab4 = () => <FinalizedTab />;

	const renderHeaderComponent = () => (
		<>
			<Typography
				style={{
					marginRight: 20,
					fontWeight: 'bold',
					color: selectedTheme.id === 'main' ? selectedTheme.primary : '#fff',
				}}
			>
				{t('Notificações.Plataforma')}:
			</Typography>
			<Select
				variant="outlined"
				margin="dense"
				value={selectedPlatform}
				onChange={handlePlatformChange}
				MenuProps={{
					MenuListProps: {
						disablePadding: true,
					},
				}}
				classes={{
					root: classes.menuItem,
				}}
				sx={{
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.vertMenuColor
							: selectedTheme.foreground,
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					height: '32px',
					fontWeight: 'bold',
					fontSize: '14px',
					marginTop: '-2px',
					borderStyle: 'none',
					'.MuiMenuItem-root.Mui-selected': {
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					},
				}}
			>
				<MenuItem
					aria-label="none"
					value="*"
					classes={{
						root: classes.menuItem,
						selected: classes.menuItem,
					}}
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					{platformINcountry === undefined
						? t('Carregando...')
						: t('Notificações.Todas')}
				</MenuItem>
				{platformINcountry?.map((item: any) => {
					return (
						<MenuItem
							classes={{
								root: classes.menuItem,
								selected: classes.menuItem,
							}}
							value={item.id}
							key={item.id}
						>
							{item.id.replace('|', ' - ')}
						</MenuItem>
					);
				})}
			</Select>
		</>
	);

	return userData.permissions?.indexOf('read:offerClassification') !== -1 ? (
		<>
			<div
				style={{
					position: 'fixed',
					zIndex: 2,
					bottom: 25,
					right: 30,
				}}
			>
				<RadialMenu menuData={menuData} />
			</div>
			<ReactiveBase
				key={selectedClient?.id}
				app="elasticsearch"
				url={elasticProxyUrl}
				transformRequest={(request: any) => {
					setRequestBody(request.body);
					return request;
				}}
				headers={{
					Authorization: bearerToken,
				}}
				theme={{
					typography: {
						fontFamily: 'Raleway, Helvetica, sans-serif',
					},
					colors: {
						primaryColor: selectedTheme.primary,
						titleColor: '#404040',
					},
				}}
			>
				<FilterBarComponent
					titleSearchField="offer.title"
					permalinkSearchField="offer.permalink"
					offerIDSearchField="offer.offerID"
					authorNameField={['author.nickname', 'author.authorName']}
					observationSearchField="offerClassification.observation"
					dateRangeField="created"
					tagSelectorField="offer.tags.tagID.keyword"
					denouncementReasonField
					classificationTypeField="offerClassification.classificationTypeID"
					sourceSearchField="offer.sourceID"
					platformSearchField="offer.platformINcountryID"
					denouncerSearchField="denouncer.denouncerName"
					citySearchField="author.cityName"
					countrySearchField="author.countryCode"
					stateSearchField="author.stateName"
					// getNewKey={() => null}
				/>
				<WindowTabs
					tab1Component={renderTab1()}
					tab2Component={renderTab2()}
					tab3Component={renderTab3()}
					// tab4Component={renderTab4()}
					tab1Title={`${t('Notificações.Classificadas')}`}
					tab2Title={`${t('Notificações.Notificadas')}`}
					tab3Title={`${t('Notificações.Removidas')}`}
					tab4Title={`${t('Notificações.Finalizados')}`}
					windowTitle={renderHeaderComponent()}
				/>
			</ReactiveBase>
		</>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};

export default Notificacoes;
